import React, { FC } from "react";

interface Props {
  title: string;
  subTitle: string;
  icon: string;
  background: string;
  className: string;
}
const Code: FC<Props> = ({ title, subTitle, icon, background, className }) => {
  return (
    <div className={className}>
      <div
        className="code-parent ralative flex flex-row justify-start bg-transparent py-6 pr-7 pl-6 mb-5 rounded-2xl md:py-5"
        style={{
          backgroundColor: `${background}`,
          boxShadow: "0px 20px 20px rgba(13, 40, 59, 0.3)",
        }}
      >
        <div className="absolute h-full w-6">
          <img
            className="mt-1.5 md:-ml-1"
            src={icon}
            alt={title}
            width="10"
            height="10"
          />
        </div>
        <div className="ml-5 flex flex-col md:ml-3">
          <span className="title text-white text-base font-bold font-Source md:text-xs">
            {title}
          </span>
          <span className="title text-white opacity-75 text-base font-normal font-Source md:text-xs">
            {subTitle}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Code;
