import React, { FC } from "react";
import ListLike from "../../listLike/listLike";
import { PaymentsSectionWrapper } from "./paymentsSection.styles";
import { navigate } from "gatsby";
import Lottie from "lottie-react";
import lottiePayments from "../../../lotties/payments.json";
import fraud from "../../../images/fraud.svg";
import instant from "../../../images/instant.svg";
import onRamp from "../../../images/on_ramp.svg";

interface Props {
  //   title: string;
}
const PaymentsSection: FC<Props> = () => {
  const paymentAnime = JSON.parse(JSON.stringify(lottiePayments));
  const listData = [
    {
      title: "Fraud Indemnification",
      subTitle: "We take on full liability for chargebacks and returns.",
      icon: fraud,
    },
    {
      title: "Instant Bank ACH Settlement",
      subTitle: "Instant account funding, and payments using bank ACH. ",
      icon: instant,
    },
    {
      title: "Bank ACH to Crypto On-ramp",
      subTitle: "Regulatory compliant, instant bank ACH to crypto purchase.",
      icon: onRamp,
    },
  ];
  return (
    <section className="section-wrapper  relative" css={PaymentsSectionWrapper}>
      <div className="curves-parent absolute top-full left-full"></div>
      <div className="relative wrapper-parent layout-width w-full">
        <div className="flex flex-row justify-between h-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-32 md:mb-0">
              <p className="font-Inter font-normal text-blue-accent text-lg content-start mb-5 md:text-base md:mb-6">
                Faster Payments
              </p>
              <p className="title-parent font-Inter text-blue-darken text-5xl font-semibold mb-4 md:text-3xl md:text-left md:tracking-tighter md:mb-6 md:mr-auto">
                Fund settlement, without the wait times
              </p>
              <p className="subtitle-parent font-Inter text-blue-darken text-base font-normal mb-8 md:text-base md:font-normal md:mb-10">
                Bank payments, international remittance, virtual cards, and
                crypto. Offer more payment options, without worrying about
                fraud.
              </p>
              <div className="list-parent flex flex-col justify-center w-full mb-6">
                {listData.map((data, i) => (
                  <ListLike
                    key={i}
                    title={data.title}
                    subTitle={data.subTitle}
                    icon={data.icon}
                  />
                ))}
              </div>
              <button
                className="font-Inter btn-parent bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 px-8 rounded-2xl md:hidden hover:bg-blue-hover focus:bg-blue-darken"
                onClick={() => navigate("/payments")}
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-start mx-auto lg:justify-center md:mb-11">
            <Lottie animationData={paymentAnime} />
          </div>
          <button
            className="font-Inter bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 px-8 rounded-2xl hidden md:block md:w-full md:max-w-sm md:mr-auto md:ml-auto hover:bg-blue-hover focus:bg-blue-darken"
            onClick={() => navigate("/payments")}
          >
            Learn more
          </button>
        </div>
      </div>
    </section>
  );
};

export default PaymentsSection;
