import React, { FC, useRef, useEffect } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import media from "../breakpoints";

type Props = {
  words: string[];
};
const Headline: FC<Props> = ({ words }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const SlideIn = keyframes`
    from {
        transform: translateY(-100%);
      }
    to {
      transform: translateY(0%);
    }
  `;
  const SlideOut = keyframes`
  from {
      transform:translateY(0%)
    }

    to {
      transform: translateY(100%)
    }
  `;
  const Word = styled.span`
    color: #3147ff;
    display: inline-block;
    text-align: center;
    position: relative;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    padding: 0px;
    ${media("md")} {
      width: 120px;
      text-align: left;
    }

    .word {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      text-align: center;
      display: inline-block;
      white-space: nowrap;
      height: 100%;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      font-style: normal;
    }
    .fadeIn {
      position: relative;
      z-index: 2;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      -o-animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-name: ${SlideIn};
      -webkit-animation-name: ${SlideIn};
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .fadeOut {
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      -o-animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-name: ${SlideOut};
      -webkit-animation-name: ${SlideOut};
    }
  `;
  useEffect(() => {
    const setTimer = ()=> setInterval(() => {
      if (!ref.current || words.length <= 1) return;
      const fadeInElement: Element =
        ref.current.getElementsByClassName("fadeIn")[0];
      const nextSibling: Element = fadeInElement?.nextSibling as Element;
      const prevSibling: Element =
        ref.current.getElementsByClassName("fadeOut")[0];
      if (prevSibling) {
        prevSibling.classList.remove("fadeOut");
      }
      if (fadeInElement) {
        if (nextSibling) {
          fadeInElement.classList.remove("fadeIn");
          fadeInElement.classList.add("fadeOut");
          nextSibling.classList.add("fadeIn");
        } else {
          fadeInElement.classList.remove("fadeIn");
          fadeInElement.classList.add("fadeOut");
          ref.current.children[0].classList.add("fadeIn");
        }
      }
    }, Math.random() * 300 + 3300);
    setTimer();
  },[]);
  useEffect(() => {
    if (ref.current) {
      ref.current.children[0].classList.add("fadeIn");
    }
  });
  return (
    <Word ref={ref}>
      {words.map((word, i) => (
        <span key={i} className="word">
          {word}
        </span>
      ))}
    </Word>
  );
};

export default Headline;
