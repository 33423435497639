import { css } from "@emotion/react";
import paymentsCurves from "../../../images/payments/payment_curves.svg";
import media from "../../breakpoints";

const PaymentsSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 700px;
  }
  .curves-parent {
    background: url(${paymentsCurves}) center no-repeat;
    width: 1624px;
    height: 542px;
    transform: translate(-79%, -100%);
  }
  .image-parent svg {
    max-width: 667px;
    width: 100%;
  }
  .title-parent {
    max-width: 600px;
    line-height: 54px;
    font-size: 44px;
    ${media("md")} {
      line-height: 38px;
      font-size: 28px;
    }
  }
  .subtitle-parent {
    max-width: 525px;
  }
  .btn-parent {
    max-width: 180px;
  }
`;

export { PaymentsSectionWrapper };
