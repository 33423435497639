import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import MainSection from "../components/homeSections/mainSection/mainSection";
import PaymentsSection from "../components/homeSections/paymentsSection/paymentsSection";
import FraudSection from "../components/homeSections/fraudSection/frauddSection";
import ComplianceSection from "../components/homeSections/complianceSection/complianceSection";
import TestimonialssSection from "../components/homeSections/testimonialsSection/testimonialsSection";
import DevelopersSection from "../components/homeSections/developersSection/developersSection";
import BlueSection from "../components/blueSetion/blueSection";

const IndexPage = () => {
  const textArr = ["Move money", " fast, ", "without risk."];
  return (
    <Layout>
      <Seo
        title="Sardine - Risk-free ACH. Instant settlement."
        description="Build on our platform to stop fraud and increase revenue."
        pathname="/"
      />
      <main>
        <MainSection />
        <PaymentsSection />
        <FraudSection />
        <ComplianceSection />
        <TestimonialssSection />
        <DevelopersSection />
        <BlueSection textArr={textArr} btnText={"Book a demo"} />
      </main>
    </Layout>
  );
};

export default IndexPage;
