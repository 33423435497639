import { css } from "@emotion/react";
import mainCurves from "../../../images/main_curves.svg";
import mainCurvesBottom from "../../../images/home_main_bottom.svg";
import media from "../../breakpoints";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 753px;
  }
  .second-heading > span {
    ${media("md")} {
      width: 132px;
    }
  }
  .curves-parent {
    background: url(${mainCurves}) center no-repeat;
    width: 1520px;
    height: 508px;
    transform: translate(-83%, 0%);
    ${media("md")} {
      transform: translate(-64%, 63%);
    }
  }
  .curves-parent-bottom {
    background: url(${mainCurvesBottom}) center no-repeat;
    width: 1624px;
    height: 542px;
    transform: translate(-44%, -114%);
    ${media("md")} {
      transform: translate(-67%, 0);
    }
  }
  .title-lg-parent {
    max-width: 590px;
    font-size: 64px;
    line-height: 78px;
    font-weight: 700;
    ${media("md")} {
      font-size: 34px;
      line-height: 44px;
      font-weight: 700;
    }
  }
  .title-parent {
    max-width: 591px;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    .bolder {
      font-weight: 500;
    }
    ${media("md")} {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .btn-parent {
    max-width: 192px;
  }

  .image-parent svg {
    max-width: 659px;
    width: 100%;
  }
  .carousels-wrapper {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
  }
`;

export { MainSectionWrapper };
