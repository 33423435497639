import { css } from "@emotion/react";
import media from "../../breakpoints";

const fraudSectionWrapper = css`
  overflow: hidden;
  .earth-overlay {
    width: 1520px;
    height: 636px;
    transform: translate(-50%, -30%);
  }
  .title-parent-lg {
    line-height: 54px;
    font-size: 44px;
    ${media("md")} {
      line-height: 38px;
      font-size: 28px;
    }
  }
  .fraud-width {
    max-width: 1440px;
    margin: 0 auto;
  }
  .ellipse {
    transform: translate(0, 30%);
  }
  .fraud {
    max-width: 100%;
    width: 480px;
    margin: 0 auto;
    ${media("md")} {
      width: 355px;
    }
  }
  .codes {
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    .code-wrapper {
      max-width: 423px;
      ${media("md")} {
        width: 90%;
        max-width: 360px;
      }
      margin: auto;
      &:nth-of-type(2),
      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(8),
      &:nth-of-type(10),
      &:nth-of-type(12) {
        max-width: 484px;
        ${media("md")} {
          width: 100%;
          max-width: 400px;
        }
        .code-parent {
          padding: 28px 24px 28px 28px;
          ${media("md")} {
            padding: 20px 12px 20px 15px;
          }
          .title {
            font-size: 16px;
            ${media("md")} {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .wrapper-parent {
    min-height: 981px;
  }
  .image-parent {
    margin-bottom: 50px;
    min-height: 776px;
    ${media("md")} {
      margin-bottom: 0;
      min-height: 355px;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto !important;
    }
  }
  .title-parent {
    max-width: 585px;
  }

  .btn-parent {
    max-width: 180px;
  }
  .accordion-parent {
    max-width: 375px;
  }
  .carousels-wrapper {
    max-height: 355px;
    max-width: 100vw;
    overflow: hidden;
  }
`;

export { fraudSectionWrapper };
