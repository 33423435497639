import styled from "@emotion/styled";
import media from "../breakpoints";

const Fraud = styled.div`
  .slick-list {
    padding: 0 !important;
  }

  .slide {
    transform: scale(0.9);
    transition: transform 300ms;
    opacity: 0.8;
    width: 444px;
    margin: 0 auto;
    ${media("md")} {
      width: calc(100% - 20px);
      max-width: 324px;
    }
  }

  .activeSlide {
    transform: scale(1);
    opacity: 1;
  }
`;
export { Fraud };
