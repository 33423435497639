import React, { FC, useState } from "react";
import Slider from "react-slick";
import Code from "../code/code";
import yellowIcon from "../../images/yellow-sq.svg";
import redIcon from "../../images/red-sq.svg";
import { Fraud } from "./fraudCarousel.styles";
interface Props {}
const FraudCarousel: FC<Props> = () => {
  const codeData = [
    {
      title: "Bad device reputation detected.",
      subTitle: "Action: Deny action.",
      icon: yellowIcon,
      background: "#141A39",
    },
    {
      title: "Suspicious copy/paste in LTM field.",
      subTitle: "Action: Submit for review",
      icon: redIcon,
      background: "#141A39",
    },
    {
      title: "TeamViewer remote software in use.",
      subTitle: "Action: Request enhanced MFA",
      icon: yellowIcon,
      background: "#141A39",
    },
    {
      title: "Bad device reputation detected.",
      subTitle: "Action: Deny action.",
      icon: yellowIcon,
      background: "#141A39",
    },
    {
      title: "Suspicious copy/paste in LTM field.",
      subTitle: "Action: Submit for review",
      icon: redIcon,
      background: "#141A39",
    },
    {
      title: "TeamViewer remote software in use.",
      subTitle: "Action: Request enhanced MFA",
      icon: yellowIcon,
      background: "#141A39",
    },
  ];
  const [cardIndex, setCardIndex] = useState(0);
  const settings = {
    infinite: true,
    // lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    vertical: true,
    autoplay: true,
    pauseOnHover: false,
    beforeChange: (current: number, next: number) => setCardIndex(next),
  };

  return (
    <Fraud>
      <Slider {...settings}>
        {codeData.map((data, index) => (
          <Code
            key={index}
            title={data.title}
            subTitle={data.subTitle}
            icon={data.icon}
            background={data.background}
            className={index === cardIndex ? "slide activeSlide" : "slide"}
          />
        ))}
      </Slider>
    </Fraud>
  );
};

export default FraudCarousel;
