import React, { FC } from "react";
import { css } from "@emotion/react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import line from "../../images/line.svg";

interface Props {
  title: string;
  subTitle: string;
  percent: string;
}
const Percent: FC<Props> = ({ title, subTitle, percent }) => {
  let isMobile = useMediaQuery("(max-width: 991.98px)");
  return (
    <div
      className="percent-wrapper my-4 mx-2 md:my-4"
      css={css`
        width: ${isMobile ? "320px" : "282px"};
        min-height: 145px;
      `}
    >
      <div className="percent-parent relative">
        <img
          src={line}
          width={5}
          height={68}
          alt="line"
          className="line absolute top-0 left-0"
        />
        <div
          className="flex flex-col"
          css={css`
            margin-left: 20px;
          `}
        >
          <p className="percent font-Inter text-grey-percent text-3xl font-semibold">
            {percent}
          </p>
          <p className="title font-Inter text-blue-darken text-base font-normal mb-3">
            {title}
          </p>
          <p className="subTitle font-Inter text-blue-medium text-sm font-normal">
            {subTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Percent;
