import React, { FC } from "react";
import Carousel from "../../carousel/carousel";
import { TestimonialsSectionWrapper } from "./testimonialsSection.styles";
import { navigate } from "gatsby";
import moon from "../../../images/testimonials/moon.svg";
import deel from "../../../images/testimonials/deel.svg";
import relay from "../../../images/testimonials/relay.svg";
import victor from "../../../images/testimonials/victor.png";
import dan from "../../../images/testimonials/dan.png";
import yoseph from "../../../images/testimonials/yoseph.png";
import moonpay_splash from "../../../images/testimonials/moonpay_splash.png";
import deel_splash from "../../../images/testimonials/deel_splash.png";
import relay_splash from "../../../images/testimonials/relay_splash.png";
interface Props {
  //   title: string;
}
const TestimonialsSection: FC<Props> = () => {
  const testimonials = [
    {
      feedback:
        "“Sardine helped us reduce friction by 2x, while still detecting 3x more fraud.”",
      hint: "At Moonpay, our mission is to enable access to cryptocurrencies to the next billion customers. To do so, we need really strong defense against a wide variety of fraud attacks, without layering the kind of friction or fear that might turn off customers who are just getting started with crypto.",
      name: "Victor Faramond",
      title: "Co-founder, CTO",
      photo: victor,
      logo: moon,
      image: moonpay_splash,
      company: "moonpay",
    },
    {
      feedback:
        "“Outwits fraudsters and still provides good customers a superior experience.”",
      hint: "Finding the equilibrium between customer acceptance and fraud control has always been a challenge, especially given fraudsters move at such a high velocity. Sardine provides the data needed to find balance and keep out the smartest bad actors, while protecting margins and customer experience.",
      name: "Dan Westgrath",
      title: "COO",
      photo: dan,
      logo: deel,
      image: deel_splash,
      company: "deel",
    },
    {
      feedback:
        "“Sardine gives us the power of an expert fraud and data science team.”",
      hint: "With the high frequency and volume of funds flowing through Relay Financial, Sardine's AI engine can flag suspicious transactions and act as our fraud operating system throughout the entire lifecycle of our customers.",
      name: "Yoseph West",
      title: "CEO",
      photo: yoseph,
      logo: relay,
      image: relay_splash,
      company: "relay",
    },
  ];
  return (
    <section
      className="section-wrapper relative"
      css={TestimonialsSectionWrapper}
    >
      <div className="curves-parent-left absolute top-0 left-0"></div>
      <div className="curves-parent-right absolute top-0 left-full"></div>
      <div className="wrapper-parent layout-width w-full pt-40">
        <div className="flex flex-col justify-start">
          <p className="font-Inter text-lg font-normal text-blue-accent content-center mx-auto mb-5 md:text-base md:mb-6">
            Testimonials
          </p>
          <Carousel data={testimonials} />
          <button
            className="font-Inter btn-parent bg-blue-accent h-12 text-white text-center text-base font-semibold mx-auto px-8 rounded-2xl mb-0 md:w-full md:max-w-sm md:mr-auto md:ml-auto hover:bg-blue-hover focus:bg-blue-darken"
            onClick={() => navigate("/contact")}
          >
            Contact us
          </button>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
