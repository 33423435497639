import React, { FC } from "react";
import ListLike from "../../listLike/listLike";
import { DevelopersSectionWrapper } from "./developersSection.styles";
import { navigate } from "gatsby-link";
import built from "../../../images/built.svg";
import global from "../../../images/global.svg";
import supervised from "../../../images/supervised.svg";
import builtFintech from "../../../images/built-fintech.svg";
import developersCover from "../../../images/home_developers_bg.png";
interface Props {
  //   title: string;
}
const DevelopersSection: FC<Props> = () => {
  const listDataFirst = [
    {
      title: "Built for Fintech ",
      subTitle:
        "Based on learnings from fighting fraud at the world's leading crypto and fintech companies.",
      icon: built,
      link: {
        text: "Sardine Tools",
        url: "/tools",
      },
    },
    {
      title: "Global coverage",
      subTitle:
        "Global from day one. Today, we protect our customers in Europe, Africa, Asia and South America.",
      icon: global,
      link: {
        text: "Contact sales",
        url: "/contact",
      },
    },
  ];
  const listDataSecond = [
    {
      title: "AI-based fraud scoring",
      subTitle:
        "We've analyzed 10's of millions of devices and users to continuously improve our machine learning models.",
      icon: supervised,
      link: {
        text: "Fraud prevention",
        url: "/fraud",
      },
    },
    {
      title: "Compliant and secure",
      subTitle:
        "Our system and processes are fully SOC-2 and GDPR compliant. ThreatWatch penetration testing.",
      icon: builtFintech,
      link: {
        text: "Regulatory compliance",
        url: "/compliance",
      },
    },
  ];
  return (
    <section
      className="section-wrapper relative pt-32"
      css={DevelopersSectionWrapper}
    >
      <div className="curves-parent-top absolute top-0 left-full"></div>
      {/* <div className="curves-parent-bottom absolute top-full left-full"></div> */}
      <div className="curves-parent-left absolute top-0 left-0"></div>
      <div className="wrapper-parent layout-width relative flex flex-col flex-nowrap justify-start w-full pt-4">
        <div className="flex flex-row justify-between w-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-20">
              <p className="font-Inter font-normal text-blue-accent text-lg content-start mb-5 md:text-base md:mb-6">
                Designed for developers
              </p>
              <p className="font-Inter title-parent text-blue-darken text-5xl font-semibold mb-4 md:text-3xl md:text-left md:tracking-tighter md:mb-6 md:mr-auto">
                One API integration to protect your valuable customer flows.
              </p>
              <p className="font-Inter subtitle-parent text-blue-darken text-base font-normal mb-8 md:tracking-tight md:text-left md:text-base md:font-normal md:mb-0">
                We built a comprehensive, easy-to-use API, so your teams don’t
                need to spend months evaluating and integrating dozens of
                disparate systems.
              </p>
              <button
                className="font-Inter btn-parent bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 px-8 rounded-2xl md:hidden hover:bg-blue-hover focus:bg-blue-darken"
                onClick={() => navigate("/developers")}
              >
                API documentation
              </button>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-center mx-auto">
            <img src={developersCover} alt="developers" />
          </div>
        </div>
        <div className="flex flex-row justify-between w-full md:flex-col">
          <div className="font-Inter list-parent flex flex-col justify-center w-full mb-6 md:mb-0">
            {listDataFirst.map((data, i) => (
              <ListLike
                key={i}
                title={data.title}
                subTitle={data.subTitle}
                icon={data.icon}
                link={data.link}
              />
            ))}
          </div>
          <div className="font-Inter list-parent flex flex-col justify-center w-full mb-6 md:mb-0">
            {listDataSecond.map((data, i) => (
              <ListLike
                key={i}
                title={data.title}
                subTitle={data.subTitle}
                icon={data.icon}
                link={data.link}
              />
            ))}
          </div>
        </div>
        <button
          className="font-Inter bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 px-8 rounded-2xl hidden md:block md:w-full md:max-w-sm md:mr-auto md:ml-auto md:mt-4 md:mb-36 hover:bg-blue-hover focus:bg-blue-darken"
          onClick={() => navigate("/developers")}
        >
          API documentation
        </button>
      </div>
    </section>
  );
};

export default DevelopersSection;
