import React, { FC } from "react";
import { fraudSectionWrapper } from "./fraudSection.styles";
import Percent from "../../percent/percent";
import FraudCarousel from "../../fraudCarousel/fraudCarousel";
import { navigate } from "gatsby";
import Lottie from "lottie-react";
import lottieFraud from "../../../lotties/fraud.json";
import ellipse from "../../../images/ellipse.svg";
interface Props {
  //   title: string;
}
const FraudSection: FC<Props> = () => {
  const FraudtAnime = JSON.parse(JSON.stringify(lottieFraud));
  const percentData = [
    {
      percent: "300%",
      title: "More fraud detected",
      subtitle:
        "Unlike traditional e-commerce fraud vendors, we monitor 1000’s of signals specific to digital financial transactions.",
    },
    {
      percent: "200%",
      title: "Less false positives",
      subtitle:
        "Convert more customers during on-boarding, and avoid adding friction that frustrates your existing customers.",
    },
    {
      percent: "90%",
      title: "Less identity fraud",
      subtitle:
        "Detect stolen or synthetic identities. Flag suspicious typing and copy/paste behavior patterns at account Sign-up.",
    },
  ];
  // let isMobile = useMediaQuery("(max-width: 991.98px)");
  return (
    <section css={fraudSectionWrapper}>
      <div className="section-wrapper wrapper-parent w-full pt-4 md:pt-28">
        <div className="fraud-width flex flex-col justify-start w-screen">
          <p className="font-Inter font-normal text-blue-accent content-center mx-auto mb-5 md:text-base md:mb-6">
            Fraud Prevention
          </p>
          <p className="title-parent-lg font-Inter text-blue-darken tracking-tight text-5xl font-semibold mx-auto mb-5 md:text-3xl md:text-center md:tracking-tighter md:mb-6 md:mr-auto md:ml-auto">
            Secure your customer’s money.
          </p>
          <p className="font-Inter text-blue-darken text-lg font-normal mx-auto mb-12 md:tracking-tight md:text-center md:text-base md:font-normal md:mb-5">
            The world's most advanced identity, behavior biometrics and device
            intelligence.
          </p>

          <div className="image-parent relative flex flex-col justify-end w-full md:w-screen">
            <img
              src={ellipse}
              className="ellipse w-screen min-w-full absolute top-0 left-0 block z-10"
              width="100%"
              height="100%"
              alt="ellipse"
            />
            <Lottie animationData={FraudtAnime} />
            <div className="codes absolute z-10 md:w-full">
              <div className="carousels-wrapper">
                <FraudCarousel />
              </div>
            </div>
            <div className="font-Inter flex flex-row justify-around mb-6 md:flex-col md:hidden">
              {percentData.map((item, i) => (
                <Percent
                  key={i}
                  percent={item.percent}
                  title={item.title}
                  subTitle={item.subtitle}
                />
              ))}
            </div>
          </div>
          <div className="font-Inter hidden flex-row justify-around mb-6 md:flex-col md:flex md:mb-11 md:mx-4 md:mt-20">
            {percentData.map((item, i) => (
              <Percent
                key={i}
                percent={item.percent}
                title={item.title}
                subTitle={item.subtitle}
              />
            ))}
          </div>
          <div className="md:mx-4">
            <button
              className="font-Inter bg-blue-accent h-12 text-white text-center text-base font-semibold mx-auto px-8 rounded-2xl mb-20  hidden md:block md:w-full md:max-w-sm md:mr-auto md:ml-auto hover:bg-blue-hover focus:bg-blue-darken"
              onClick={() => navigate("/fraud")}
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FraudSection;
