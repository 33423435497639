import styled from "@emotion/styled";
import React, { FC } from "react";
import Slider from "react-slick";
import logo1 from "../../../images/logos/relay.svg";
import logo3 from "../../../images/logos/transak.svg";
import logo4 from "../../../images/logos/moonpay.svg";
import logo5 from "../../../images/logos/luno_logo.svg";
import logo7 from "../../../images/logos/brex.svg";
import logo8 from "../../../images/logos/chippercash.svg";
import logo9 from "../../../images/logos/onjuno.png";
import logo10 from "../../../images/logos/funneldash.svg";
import logo11 from "../../../images/logos/kriptomat.svg";
import logo12 from "../../../images/logos/donut.svg";
import logo14 from "../../../images/logos/shoppingio.png";
import logo15 from "../../../images/logos/dharma.png";
import logo16 from "../../../images/logos/zipmex.svg";
import logo17 from "../../../images/logos/bakkt.svg";
import logo20 from "../../../images/logos/zolve.svg";
import atob from "../../../images/logos/AtoB.png";
import anique from "../../../images/logos/Anique.JP_Logo.svg";
import btcc from "../../../images/logos/BTCC_Logo.svg";
import digit from "../../../images/logos/Digit_Logo.svg";
import fortress from "../../../images/logos/Fortress_Logo.svg";
import sila from "../../../images/logos/Sila_Logo.svg";
import wert from "../../../images/logos/Wert_Logo.svg";
import ftx from "../../../images/logos/ftx.svg";

interface Props {
  //settings: Object;
  //   subTitle: string;
  //   color: string;
  //   index: number;
}
const HomeCarousel: FC<Props> = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  const logos = [
    [
      ftx,
      logo1,
      logo3,
      logo4,
      logo5,
      logo7,
      logo8,
      logo9,
      logo10,
      logo11,
      logo17,
      logo20,
    ],
    [logo12, logo14, logo15, logo16, atob, anique, btcc, digit, fortress, sila, wert],
  ];
  const CardsSlider = styled.div`
    .slick-dots li button::before {
      font-size: 11px;
      color: #d9dbe1 !important;
      opacity: 1 !important;
    }
    ul.slick-dots li button:hover::before {
      color: #273372 !important;
    }
    ul.slick-dots li button:focus::before {
      color: #141a39 !important;
    }
    .slick-dots li.slick-active button::before {
      opacity: 1 !important;
      color: #3147ff !important;
    }
    .slick-list {
      padding-bottom: 20px;
      margin: 0 auto;
      max-width: 1007px;
    }
  `;
  return (
    <CardsSlider className="mb-16 w-full">
      <Slider {...settings}>
        {logos.map((logoblock, index) => (
          <div key={index} className="wrapper">
            <div className="flex flex-row flex-wrap justify-between">
              {logoblock.map((logo, i) => (
                <div key={i} className="mb-11 w-1/2">
                  <img
                    src={logo}
                    alt="logos"
                    height=""
                    width=""
                    className=" mx-auto"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </CardsSlider>
  );
};

export default HomeCarousel;
