import { css } from "@emotion/react";
import media from "../../breakpoints";

const ComplianceSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 700px;
  }

  .image-parent img {
    max-width: 714px;
    width: 100%;
  }

  .title-parent {
    max-width: 510px;
    line-height: 54px;
    font-size: 44px;
    ${media("md")} {
      line-height: 38px;
      font-size: 28px;
    }
  }
  .subtitle-parent {
    max-width: 565px;
  }
  .btn-parent {
    max-width: 180px;
  }
  .percent-build {
    .percent-wrapper {
      margin-right: 84px;
    }
  }
`;

export { ComplianceSectionWrapper };
