import { css } from "@emotion/react";
import customerCurvesLeft from "../../../images/customer_left_curves.svg";
import customerCurvesRight from "../../../images/customer_right_curves.svg";
import media from "../../breakpoints";

const TestimonialsSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 981px;
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 1249px;
    }
  }
  .title-parent {
    max-width: 585px;
  }
  .btn-parent {
    max-width: 177px;
    ${media("md")} {
      max-width: 24rem;
    }
  }
  .accordion-parent {
    max-width: 375px;
  }
  .curves-parent-left {
    background: url(${customerCurvesLeft}) center no-repeat;
    width: 1624px;
    height: 542px;
    transform: translate(-21%, 0%);
    ${media("md")} {
      transform: translate(-27%, -7%);
    }
  }
  .curves-parent-right {
    background: url(${customerCurvesRight}) center no-repeat;
    width: 1624px;
    height: 542px;
    transform: translate(-73%, 0%);
    ${media("md")} {
      transform: translate(-64%, 11%);
    }
  }
`;

export { TestimonialsSectionWrapper };
