import { css } from "@emotion/react";
import media from "../../breakpoints";
import apiCurves from "../../../images/api_blue-curve.svg";
import apiCurvesBottom from "../../../images/api_bottom_right_curves.svg";
import apiCurvesLeft from "../../../images/api_left_curves.svg";

const DevelopersSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 845px;
  }
  .curves-parent-top {
    background: url(${apiCurves}) center no-repeat;
    width: 1805px;
    height: 602px;
    transform: translate(-67%, -7%);
  }
  .curves-parent-bottom {
    background: url(${apiCurvesBottom}) center no-repeat;
    width: 1805px;
    height: 602px;
    transform: translate(-63%, -100%);
  }
  .curves-parent-left {
    background: url(${apiCurvesLeft}) center no-repeat;
    width: 1824px;
    height: 609px;
    transform: translate(-25%, 40%);
  }
  .image-parent img {
    max-width: 590px;
    width: 100%;
    ${media("md")} {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
  }
  .title-parent {
    max-width: 530px;
    line-height: 54px;
    font-size: 44px;
    ${media("md")} {
      line-height: 38px;
      font-size: 28px;
    }
  }
  .subtitle-parent {
    max-width: 570px;
  }
  .btn-parent {
    max-width: 240px;
  }
`;

export { DevelopersSectionWrapper };
