import React, { FC } from "react";
import ListLike from "../../listLike/listLike";
import { ComplianceSectionWrapper } from "./complianceSection.styles";
import Percent from "../../percent/percent";
import { navigate } from "gatsby";
import aml from "../../../images/aml.svg";
import sanctions from "../../../images/sanctions.svg";
import kyc from "../../../images/kyc.svg";
import buidCover from "../../../images/home_build_bg.svg";
interface Props {
  //   title: string;
}
const ComplianceSection: FC<Props> = () => {
  const listData = [
    {
      title: "AML Transaction Monitoring ",
      subTitle: "IRS avoidance and crypto, integrated with Coinbase Analytics.",
      icon: aml,
    },
    {
      title: "Sanctions & Adverse Media",
      subTitle: "PEP, SDN, OFAC screening and on-going monitoring.",
      icon: sanctions,
    },
    {
      title: "KYC Document Verification",
      subTitle: "AI-based detection of stolen or synthetic identities.",
      icon: kyc,
    },
  ];
  const percentData = [
    {
      percent: "< 2 weeks",
      title: "Time to integrate",
      subtitle:
        "Save time evaluating, integrating, and negotiating with dozens of 3rd party risk and compliance vendors.",
    },
    {
      percent: "$500K",
      title: "Operational savings",
      subtitle:
        "Save time and money by automating manual reviews. Setup EDD triggers, with our easy to use rule-builder.",
    },
    {
      percent: "300%",
      title: "Less payment fraud",
      subtitle:
        "Detect stolen cards, bank accounts, or multiple devices linked to the same account when funding digital wallets.",
    },
  ];
  return (
    <section
      className="section-wrapper relative pt-24 md:pt-10"
      css={ComplianceSectionWrapper}
    >
      <div className="relative wrapper-parent layout-width w-full">
        <div className="flex flex-row justify-between h-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-16 md:mb-0">
              <p className="font-Inter font-normal text-blue-accent text-lg content-start mb-5 md:text-base md:mb-6">
                Regulatory Compliance
              </p>
              <p className="font-Inter title-parent tracking-tight text-blue-darken text-5xl font-semibold mb-4 md:text-3xl md:text-left md:tracking-tighter md:mb-6 md:mr-auto">
                Build faster, spend less on compliance
              </p>
              <p className="font-Inter subtitle-parent text-blue-darken text-base font-normal mb-8 md:tracking-tight md:text-left md:text-base md:font-normal md:mb-10">
                Comes bundled with 100’s of pre-configured and tested
                typologies. Easily customizable with our no-code Rule Builder.
              </p>
              <div className="font-Inter list-parent flex flex-col justify-center w-full mb-6">
                {listData.map((data, i) => (
                  <ListLike
                    key={i}
                    title={data.title}
                    subTitle={data.subTitle}
                    icon={data.icon}
                  />
                ))}
              </div>
              <button
                className="font-Inter btn-parent bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 px-8 rounded-2xl md:hidden hover:bg-blue-hover focus:bg-blue-darken"
                onClick={() => navigate("/compliance")}
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-center -mr-16 lg:-mr-6 md:mr-auto md:ml-auto md:mb-7">
            <img src={buidCover} alt="payments" />
          </div>
        </div>
        <div className="font-Inter percent-build flex flex-row justify-start mb-0 md:flex-col md:mb-6">
          {percentData.map((item, i) => (
            <Percent
              key={i}
              percent={item.percent}
              title={item.title}
              subTitle={item.subtitle}
            />
          ))}
        </div>
        <button
          className="font-Inter bg-blue-accent h-12 text-white text-center text-base font-semibold ml-0 px-8 rounded-2xl hidden md:block md:w-full md:max-w-sm md:mr-auto md:ml-auto hover:bg-blue-hover focus:bg-blue-darken"
          onClick={() => navigate("/compliance")}
        >
          Learn more
        </button>
      </div>
    </section>
  );
};

export default ComplianceSection;
