import React, { FC } from "react";
import { MainSectionWrapper } from "./mainSection.styles";
import HomeCarousel from "../homeCarousel/homeCarousel";
import Headline from "../../headline/headline";
import { navigate } from "gatsby";
import Lottie from "lottie-react";
import heroPayments from "../../../lotties/hero.json";
import logo1 from "../../../images/logos/relay.svg";
import logo3 from "../../../images/logos/transak.svg";
import logo4 from "../../../images/logos/moonpay.svg";
import logo5 from "../../../images/logos/luno_logo.svg";
import logo7 from "../../../images/logos/brex.svg";
import logo8 from "../../../images/logos/chippercash.svg";
import logo9 from "../../../images/logos/onjuno.png";
import logo10 from "../../../images/logos/funneldash.svg";
import logo11 from "../../../images/logos/kriptomat.svg";
import logo12 from "../../../images/logos/donut.svg";
import logo14 from "../../../images/logos/shoppingio.png";
import logo15 from "../../../images/logos/dharma.png";
import logo16 from "../../../images/logos/zipmex.svg";
import logo17 from "../../../images/logos/bakkt.svg";
import logo20 from "../../../images/logos/zolve.svg";
import atob from "../../../images/logos/AtoB.png";
import anique from "../../../images/logos/Anique.JP_Logo.svg";
import btcc from "../../../images/logos/BTCC_Logo.svg";
import digit from "../../../images/logos/Digit_Logo.svg";
import fortress from "../../../images/logos/Fortress_Logo.svg";
import sila from "../../../images/logos/Sila_Logo.svg";
import wert from "../../../images/logos/Wert_Logo.svg";
import ftx from "../../../images/logos/ftx.svg";


interface Props {
  //   title: string;
}
const MainSection: FC<Props> = () => {
  const heroAnime = JSON.parse(JSON.stringify(heroPayments));
  const firstHeading = ["ACH.", "Card."];
  const secondHeading = ["Wallets.", "Crypto.", "NFT."];
  return (
    <section className="section-wrapper relative" css={MainSectionWrapper}>
      <div className="curves-parent absolute top-0 left-full"></div>
      <div className="curves-parent-bottom absolute top-full left-0"></div>
      <div className="wrapper-parent layout-width bg-transparent relative w-full pt-24">
        <div className="flex flex-row justify-between h-full mb-10 md:flex-col md:mb-14">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-16 md:mb-0">
              <p className="font-Inter title-lg-parent tracking-tight text-blue-darken text-6xl font-bold content-start mb-10 lg:text-6xl md:text-4xl md:font-bold md:text-center md:mb-8 md:mr-auto md:ml-auto">
                <span className="md:ml-6">Risk-free </span>
                <span className="text-blue-accent">
                  <Headline words={firstHeading} />
                </span>
                <br />
                Instant settlement
                <br />
                <span className="md:ml-6">to </span>
                <span className="second-heading text-blue-accent">
                  <Headline words={secondHeading} />
                </span>
              </p>
              <p className="font-Inter title-parent text-blue-darken text-base font-normal mb-10 md:text-base md:font-normal md:mb-6 md:text-center md:mr-auto md:ml-auto">
                Our team built the fraud prevention and compliance
                infrastructure that scaled both Coinbase and Revolut. Now, you
                can build on our platform to stop payment fraud and increase
                customer conversion.
              </p>
              <button
                className="font-Inter btn-parent bg-blue-accent h-12 text-white text-base font-semibold ml-0 px-8 rounded-2xl md:hidden hover:bg-blue-hover focus:bg-blue-darken"
                onClick={() =>
                  navigate("/contact", { state: { source: "book_demo" } })
                }
              >
                Book a demo
              </button>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-end -mr-28 lg:-mr-20 lg:justify-center md:mr-auto md:ml-auto md:mb-12">
            <Lottie animationData={heroAnime} />
          </div>
          <button
            className="font-Inter hidden bg-blue-accent h-12 text-white text-base font-semibold ml-0 px-8 rounded-2xl md:block md:w-full md:max-w-sm md:mr-auto md:ml-auto hover:bg-blue-hover focus:bg-blue-darken"
            onClick={() =>
              navigate("/contact", { state: { source: "book_demo" } })
            }
          >
            Book a demo
          </button>
        </div>
        <div className="carousels-wrapper pt-10 pb-32 md:hidden">
          <div className="top-carousel mb-7 flex flex-row flex-nowrap justify-between mx-4">
            {[ftx, logo1, logo3, logo4, logo5, logo7, logo8, logo9, logo10, logo11, anique].map(l => (
              <img
                className="auto-img mx-4"
                src={l}
                alt="logo"
              />
            ))}
          </div>
          <div className="bottom-carousel flex flex-row flex-nowrap justify-between mx-4">
          {[logo12, logo14, logo15, logo16, logo17, logo20, atob, btcc, digit, fortress, sila, wert].map(l => (
              <img
                className="auto-img mx-4"
                src={l}
                alt="logo"
              />
            ))}
          </div>
        </div>
        <div className="hidden flex-row flex-wrap justify-between md:flex">
          <HomeCarousel />
        </div>
      </div>
    </section>
  );
};

export default MainSection;
